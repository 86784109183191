<template>
  <div id="schedule-imports-table">
    <Table
      header="Lots"
      :params="aParams"
      :focusable="false"
      :loading.sync="oLoading.table"
      paginated
      :show-detail-icon="false"
      apiUrl="file_import"
      class="imports-table"
      ref="table"
    >
      <b-table-column
        :label="$t('global.fileLabel')"
        v-slot="props"
        :td-attrs="tdAttrs"
        width="350"
      >
        {{ getNameFile(props.row) || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.uploadDate')" v-slot="props" :td-attrs="tdAttrs">
        {{ formatDate(props.row.start_date) }}
      </b-table-column>
      <b-table-column :label="$t('global.processingDate')" v-slot="props" :td-attrs="tdAttrs">
        {{ formatDate(props.row.end_date) || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.fundingSrc')" v-slot="props" :td-attrs="tdAttrs">
        {{ getFounding(props.row) }}
      </b-table-column>
      <b-table-column :label="$t('global.uploadedBy')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.created_by_full_name }}
      </b-table-column>
      <b-table-column :label="$t('global.status')" v-slot="props" :td-attrs="tdAttrs">
        {{ statusColumn(props.row.success_records, props.row.records_on_file, props.row.end_date) }}
      </b-table-column>
      <b-table-column v-slot="props" :td-attrs="tdAttrs">
        <div class="flex">
          <Link
            :autoLoading="true"
            icon="download"
            :label="$t('global.download')"
            @click="onDownloadFile(props.row)"
            :permission="Permission.download"
          />
          <Link
            class="ml-2"
            :autoLoading="false"
            icon="information-outline"
            :label="$t('global.viewDetails')"
            @click="onViewDetails(props.row)"
            :permission="Permission.read"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import Link from '@/components/Link';
import Table from '@/components/Table';
import { downloadFile } from '@/utils/Presigned';
import { Permissions } from '@/utils/Secure';
import { replace, split } from 'ramda';

export default {
  components: {
    Link,
    Table
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { fileImports } }) => {
      if (type == 'schedule/fileImports') {
        if (fileImports.type === 'update') {
          this.$refs.table.reload();
        }
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      oLoading: { table: this.loading, rowId: 0, download: false },
      unsubscribe: null,
      fundingSource: null,
      name: null
    };
  },
  computed: {
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      const aParams = ['sort=start_date'];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (value && value != 'all') aParams.push(`${key}=${value}`);
      });
      return aParams;
    },
    Permission() {
      return Permissions.Imports;
    }
  },
  methods: {
    statusColumn(success, records, endDate) {
      if (success === records) return this.$t('global.complete');
      else if (endDate) return `${success}/${records}`;
      else return this.$t('global.processing');
    },
    getFounding({ file_name }) {
      const foundingCSV = split(/_cdp\d+_/, file_name)[1] || '-';
      return replace('.csv', '', foundingCSV);
    },
    getNameFile(data) {
      return data.file_name.split(`import/unattended_deliveries/`)[1];
    },
    formatDate(date) {
      if (!date) return '-';
      const utcDate = this.$moment.utc(date).toDate();
      const delivery = this.$moment(utcDate).format('MM/DD/Y hh:mm A');
      return delivery;
    },
    async getData() {
      await this.$refs.table.getData({ clear: true });
    },
    onClick(value) {
      this[value.event]();
    },
    onViewDetails(row) {
      this.$router.push({ path: `/schedule/imports/${row.group_id}` });
    },
    async onDownloadFile(row) {
      try {
        const file_name = row.file_name;
        const src = await downloadFile({ apiUrl: `/presigned/${file_name}/download`, file_name });
        if (src) window.location = src;
      } catch (error) {
        console.error(error);
      }
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    params: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="sass" scoped>
#schedule-imports-table
  margin-top: -35px
  border-radius: $br-md
  .table-container
    min-height: calc(100% - 120px)
.imports-table
  ::v-deep
    th
      z-index: 39!important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .table-wrapper
      height: calc(100vh - 262px)
.w-130
  width: 130px
.w-140
  width: 140px
.w-250
  width: 250px
</style>
